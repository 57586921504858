
import { ref } from 'vue'
import { user } from '@/states/login'
import { Toast, Notify } from 'vant'
import { useRouter,useRoute } from 'vue-router'
import fetchGlobalStates from '@/states/init'
import { setToken } from '@/utils/auth'
import { ApiResponse } from 'momai'
import { sendBindCode, verifyCode } from '@/api/universal/login'

export default {
  setup: () => {
    const router = useRouter()
    const route = useRoute()
    const redirect_uri = route.query?.redirect_uri
    const userId = ref('')
    return {
      onSubmit: (values: any) => {
        verifyCode({ ...values, userId: userId.value }).then(({ token, userInfo }) => {
          setToken({ token, ...userInfo })
          Notify({
            type: 'success',
            message: '手机号绑定成功！'
          })
          window.location.href = redirect_uri+''
          return false
          //router.push('/')

        }).catch(({ code }) => {
          if (code === 4) {
            router.push('/account-pending')
          }
        })
      },
      sendCode: (mobile: string, startCount: () => void) => {
        sendBindCode({
          ...user.value,
          mobile
        }).then(r => {
          const { token, userInfo } = r
          if (token) {
            Notify({
              message: '该手机号为原小程序账号，已为您绑定！',
              type: 'success'
            })
            setToken({
              token,
              ...userInfo
            })
            fetchGlobalStates()
            router.push('/')
            return
          }
          userId.value = String(user.value.id)
          Toast.success('验证码发送成功！')
          startCount()
        }).catch(({ code, data }: ApiResponse) => {
          switch (code) {
            case 5:
              startCount()
              userId.value = data
          }
        })
      },
      toagreement: () => {
        router.push('/agreement')
      }
    }
  }
}
